
const outdatedPopup = () => {
  import('whatwg-fetch').then(() => {
    fetch(`/cvi/outdatedbrowser/api/${document.documentElement.lang}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then((result) => {
        const headingText = result.browserPopupHeading
        const bodyText = result.browserPopupDescription
        const popupHtml = `<div class="c-browser-popup js-browser-popup">
                            <div class="c-browser-popup__inner o-content-box">
                                <h2 class="u-mb--m">
                                    ${headingText}
                                </h2>
                                <div class="c-browser-popup__content u-mb--m">
                                    ${bodyText}
                                </div>
                                <ul class="c-browser-popup__list u-bare-list">
                                    <li class="firefox"><a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a></li>
                                    <li class="edge"><a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a></li>
                                    <li class="chrome"><a href="https://www.google.com/chrome/" target="_blank">Chrome</a></li>
                                    <li class="safari"><a href="https://support.apple.com/downloads/safari" target="_blank">Safari</a></li>
                                </ul>
                                <div class="c-browser-popup__close js-browser-popup-close"></div>
                            </div>
                        </div>`

        document.body.innerHTML += popupHtml

        const popup = document.querySelector('.js-browser-popup')
        const button = popup.querySelector('.js-browser-popup-close')

        popup.classList.add('active')
        button.addEventListener('click', () => {
          popup.classList.remove('active')
        }, false)
      })
  })
}

export default outdatedPopup
